export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75')
];

export const server_loads = [0,4,8];

export const dictionary = {
		"/": [11],
		"/(admin)/admin": [~14,[2],[3]],
		"/(admin)/admin/login": [~15,[2],[3]],
		"/(admin)/admin/password-reset": [~16,[2],[3]],
		"/(admin)/admin/password-reset/success": [~18,[2],[3]],
		"/(admin)/admin/password-reset/[token]": [~17,[2],[3]],
		"/(admin)/admin/presenter/new": [~51,[2],[3]],
		"/(admin)/admin/presenter/[presenterSlug]": [~19,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/discounts": [~21,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/discounts/new": [~23,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/discounts/[discountId]": [~22,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/orders": [~24,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/orders/[orderId]": [~25,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/orders/[orderId]/edit": [~26,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/performances": [~27,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/performances/new": [~36,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/performances/[performanceSlug]/edit-seats": [~29,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/performances/[performanceSlug]/edit": [~28,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/performances/[performanceSlug]/info-mails": [~30,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/performances/[performanceSlug]/info-mails/new": [~31,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/performances/[performanceSlug]/sell": [~32,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/performances/[performanceSlug]/tally": [~33,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/performances/[performanceSlug]/verify": [~34,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/performances/[performanceSlug]/visitor-list": [~35,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/print/[orderId]": [~37,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/print/[orderId]/template": [~38],
		"/(admin)/admin/presenter/[presenterSlug]/sell": [~39,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/sepa-export": [~40,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/sepa-export/[secretId]": [~41,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/settings": [~42,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/settings/payment": [~43,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/settings/shipping": [~44,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/settings/users": [~45,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/settings/vouchers": [~46,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/tickets/[ticketId]/edit": [~47,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/vouchers": [~48,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/vouchers/sell": [~50,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/vouchers/[voucherId]": [~49,[2,4],[3,5]],
		"/(admin)/admin/presenter/[presenterSlug]/[...path]": [20,[2,4],[3,5]],
		"/(admin)/admin/users/me": [~53,[2],[3]],
		"/(admin)/admin/users/new": [~54,[2],[3]],
		"/(admin)/admin/users/[userId]": [~52,[2],[3]],
		"/shop/[presenterSlug]": [~65,[6,8],[7,9]],
		"/shop/[presenterSlug]/checkout/cart": [~68,[6,8],[7,9,10]],
		"/shop/[presenterSlug]/checkout/confirm": [~69,[6,8],[7,9,10]],
		"/shop/[presenterSlug]/checkout/payment": [~70,[6,8],[7,9,10]],
		"/shop/[presenterSlug]/checkout/shipping": [~71,[6,8],[7,9,10]],
		"/shop/[presenterSlug]/checkout/stripe/return": [~72,[6,8],[7,9,10]],
		"/shop/[presenterSlug]/checkout/thanks": [~73,[6,8],[7,9,10]],
		"/shop/[presenterSlug]/gebuehren": [~74,[6,8],[7,9]],
		"/shop/[presenterSlug]/gutscheine": [~75,[6,8],[7,9]],
		"/shop/[presenterSlug]/[page=staticShopPage]": [~66,[6,8],[7,9]],
		"/shop/[presenterSlug]/[performanceSlug]": [~67,[6,8],[7,9]],
		"/(admin)/sudo/banks": [~55,[2],[3]],
		"/(admin)/sudo/orders": [~56,[2],[3]],
		"/(admin)/sudo/postal-dispatch": [~57,[2],[3]],
		"/(admin)/sudo/presenters": [~58,[2],[3]],
		"/(admin)/sudo/stats": [~59,[2],[3]],
		"/(admin)/sudo/unused-images": [~60,[2],[3]],
		"/(admin)/sudo/users": [~61,[2],[3]],
		"/(admin)/sudo/worker": [~62,[2],[3]],
		"/(admin)/verify-mail/[slug]": [~64,[2],[3]],
		"/(admin)/verify/[ticketId]": [~63,[2],[3]],
		"/(admin)/[staticPage=staticAdminPage]": [~13,[2],[3]],
		"/(admin)/[...path]": [12,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';